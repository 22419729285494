// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-bluefin-gatsby-plugin-fisherman-page-creator-templates-cutilier-service-category-template-jsx": () => import("./../../../../../node_modules/@bluefin/gatsby-plugin-fisherman-page-creator/templates/cutilier/ServiceCategoryTemplate.jsx" /* webpackChunkName: "component---node-modules-bluefin-gatsby-plugin-fisherman-page-creator-templates-cutilier-service-category-template-jsx" */),
  "component---node-modules-bluefin-gatsby-plugin-fisherman-page-creator-templates-cutilier-service-item-template-jsx": () => import("./../../../../../node_modules/@bluefin/gatsby-plugin-fisherman-page-creator/templates/cutilier/ServiceItemTemplate.jsx" /* webpackChunkName: "component---node-modules-bluefin-gatsby-plugin-fisherman-page-creator-templates-cutilier-service-item-template-jsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-careers-jsx": () => import("./../../../src/pages/careers.jsx" /* webpackChunkName: "component---src-pages-careers-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-gift-card-jsx": () => import("./../../../src/pages/gift-card.jsx" /* webpackChunkName: "component---src-pages-gift-card-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-new-here-start-here-jsx": () => import("./../../../src/pages/new-here-start-here.jsx" /* webpackChunkName: "component---src-pages-new-here-start-here-jsx" */),
  "component---src-pages-non-surgical-facelift-faqs-jsx": () => import("./../../../src/pages/non-surgical-facelift-faqs.jsx" /* webpackChunkName: "component---src-pages-non-surgical-facelift-faqs-jsx" */),
  "component---src-pages-packages-jsx": () => import("./../../../src/pages/packages.jsx" /* webpackChunkName: "component---src-pages-packages-jsx" */),
  "component---src-pages-reservation-policy-jsx": () => import("./../../../src/pages/reservation-policy.jsx" /* webpackChunkName: "component---src-pages-reservation-policy-jsx" */),
  "component---src-pages-services-jsx": () => import("./../../../src/pages/services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */),
  "component---src-pages-skin-care-at-home-jsx": () => import("./../../../src/pages/skin-care-at-home.jsx" /* webpackChunkName: "component---src-pages-skin-care-at-home-jsx" */),
  "component---src-pages-special-offers-jsx": () => import("./../../../src/pages/special-offers.jsx" /* webpackChunkName: "component---src-pages-special-offers-jsx" */)
}

